import * as React from 'react';
import { List, Datagrid, useNotify, TextField, EditButton, TextInput, Pagination as RaPagination, PaginationActions as RaPaginationActions } from 'react-admin';
import { Button, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const { REACT_APP_API_URL } = process.env;

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export const SalesList = props => {
    const notify = useNotify();
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [openEmailModal, setOpenEmailModal] = React.useState(false);
    const [itemId, setItemId] = React.useState("");
    const [emailId, setEmailId] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [displaySaunaOnboardBtn, setDisplaySaunaOnboardBtn] = React.useState(false);
    const [saunaOnboardWarningFlag, setSaunaOnboardWarningFlag] = React.useState(false);
    const [dropdownCountries, setDropdownCountries] = React.useState([]);

    let token = localStorage.getItem('employee_token');

    const handleOpenEmailModal = () => {
        setOpenEmailModal(true);
    };

    const handleCloseEmailModal = () => {
        setSaunaOnboardWarningFlag(false)
        setOpenEmailModal(false);
        setItemId("");
        setEmailId("");
        setCountry("");
    };

    const PaginationActions = props => <RaPaginationActions {...props} color="primary" showFirstButton showLastButton />;

    const Pagination = props => <RaPagination {...props} rowsPerPageOptions={[10, 25, 50, 100]} ActionsComponent={PaginationActions} sx={styles.pagination} />;

    const filters = [<TextInput sx={styles.input} variant="outlined" label="Search" source="search" alwaysOn placeholder={"Search by email or name"} />];

    const postRowStyle = (record, index) => {
        if (record.trasactionTableDetail) {
            return { backgroundColor: record?.transactionEmailId?.toLowerCase() === record?.trasactionTableDetail?.transactionEmailId?.toLowerCase() || record?.trasactionTableDetail?.transactionEmailId?.toLowerCase() === record?.emailId?.toLowerCase() ? '#fffcbb' : 'white' }
        } else {
            return { backgroundColor: 'white' }
        }
    }

    const addToSaunaOwnerMaster = async () => {
        try {
            const request = new Request(`${REACT_APP_API_URL}/customercare/sales/addSaunaOwnerMaster`, {
                method: 'POST',
                body: JSON.stringify({ itemId: itemId?.toUpperCase(), emailId: emailId?.toLowerCase(), country: country?.toLowerCase() }),
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
            });
            let resp = await fetch(request);
            let body = await resp.json();
            if (body.result === true) {
                notify(`${body.value}`, { type: 'success' });
                handleCloseEmailModal()
            } else {
                notify(`${body.value}`, { type: 'warning' });
                handleCloseEmailModal()
            }
        } catch (err) {
            notify(`${err.message}`, { type: 'error' });
            handleCloseEmailModal()
        }
    }

    const checkIfSaunaAlreadyOnboarded = async () => {
        if (itemId.trim() == "" || emailId.trim() == "" || country.trim() == "") {
            notify(`please fill all the fields before assigning the sauna`, { type: 'warning' });
        }
        else {
            try {
                const request = new Request(`${REACT_APP_API_URL}/customercare/sales/checkIfOnboarded`, {
                    method: 'POST',
                    body: JSON.stringify({ itemId: itemId.toUpperCase() }),
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
                });
                let resp = await fetch(request);
                let body = await resp.json();
                if (body.result) {
                    notify(`this sauna is already taken`, { type: 'warning' });
                    setSaunaOnboardWarningFlag(true)
                } else {
                    await addToSaunaOwnerMaster();
                    handleCloseEmailModal();
                }
            } catch (err) {
                notify(`${err.message}`, { type: 'error' });
                handleCloseEmailModal()
            }
        }
    };


    const changeEmailModalBody = (
        <div style={modalStyle} className={classes.paper}>
            {/* <h2 id="simple-modal-title">Alert</h2> */}
            {!saunaOnboardWarningFlag ? (
                <div>
                    <div className={classes.title}>
                        <p id="simple-modal-description">
                            Enter the sauna Id and  email address of the user
                        </p>
                        <input className={classes.input} type="text" name="itemId" placeholder="Sauna Id" value={itemId} onChange={e => setItemId(e.target.value)} required />
                        <input className={classes.input} type="email" name="email" placeholder="Email Id" value={emailId?.toLowerCase()} onChange={e => setEmailId(e.target.value.toLowerCase())} required />
                        <div className={classes.dropDown}>
                            <Dropdown options={dropdownCountries} onChange={e => { setCountry(e.value) }} placeholder="Country" />
                        </div>
                    </div>
                    <div className={classes.modalButton}>
                        <Button onClick={(e) => checkIfSaunaAlreadyOnboarded()}>assign sauna</Button>
                    </div>
                </div>
            ) :
                <div>
                    <div className={classes.title}>
                        <p id="simple-modal-description">
                            this sauna id has already been assigned to another user. are you sure that you want to remove the ownership of the existing user and overwrite the data?
                        </p>
                    </div>
                    <div className={classes.modalButton}>
                        <Button onClick={(e) => addToSaunaOwnerMaster()}>yes</Button>
                        <Button onClick={(e) => handleCloseEmailModal()}>no</Button>
                    </div>
                </div>
            }
        </div>
    );

    React.useEffect(async () => {
        try {
            let token = localStorage.getItem('employee_token')
            const request = new Request(`${REACT_APP_API_URL}/customercare/sales/fetchSaunaOnboardAccessEmails`, {
                method: 'POST',
                body: JSON.stringify({ userId: props.id }),
                headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
            });
            let resp = await fetch(request)
            let body = await resp.json()
            if (body.result) {
                setDisplaySaunaOnboardBtn(true)
                setDropdownCountries(body.countries)
            }
            else {
                setDisplaySaunaOnboardBtn(false)
            }
        } catch (err) {
            notify(`${err.message}`, { type: 'error' });
            setDisplaySaunaOnboardBtn(false)
        }
    }, []);

    return (
        <div>
            <div className={classes.saunaOnboard}>
                <div className={classes.labelingDiv}>
                    <div className={classes.labelColor}></div>
                    <div className={classes.labelText}>Sauna Onboarded</div>
                </div>
                <div className={classes.changeEmailWithLabel}>
                    {displaySaunaOnboardBtn ? (<Button size='medium' onClick={() => handleOpenEmailModal()}>assign new Suana id</Button>) : ''}
                </div>
            </div>
            <List {...props} title="Sales" pagination={<Pagination />} actions={false} filters={filters} exporter={false} sx={styles.listMargin}>
                <Datagrid {...props} bulkActionButtons={false} rowStyle={postRowStyle} sx={styles.dataGrid}>
                    <TextField source="name" />
                    <TextField source="emailId" />
                    <TextField source="transactionName" />
                    <TextField source="transactionEmailId" />
                    <TextField source="transactionDate" />
                    <TextField source="itemId" />
                    <EditButton />
                </Datagrid>
            </List>
            <Modal open={openEmailModal} onClose={handleCloseEmailModal} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
                {changeEmailModalBody}
            </Modal>
        </div>
    );
}

const styles = {
    pagination: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        '& button': {
            padding: '10px',
            borderRadius: '25px',
            minHeight: '30px',
            minWidth: '30px',
            fontSize: '14px',
            fontWeight: '600'
        },
        '& .MuiTablePagination-toolbar': {
            padding: '0px'
        }
    },
    input: {
        marginTop: '15px',
        marginBottom: '15px',
        "@media (max-width: 700px)": {
            marginLeft: '0px',
            marginRight: '0px'
        }
    },
    listMargin: {
        marginLeft: '10px',
        marginRight: '10px'
    },
    dataGrid: {
        '& .RaDatagrid-table': {
            tableLayout: 'fixed',
            wordBreak: 'break-word',
            borderCollapse: 'inherit',
            border: '1px solid #d1cfc9',
            borderRadius: '5px',
            "@media (max-width: 550px)": {
                border: '2px solid #d1cfc9'
            }
        },
        '& th': {
            padding: '9px',
            fontSize: '16px',
            fontWeight: '600',
            "@media (max-width: 700px)": {
                fontSize: '14px'
            }
        },
        '& td': {
            padding: '9px',
            '& a': {
                fontSize: '15px',
                fontWeight: '700',
                "@media (min-width: 700px)": {
                    width: 'fit-content',
                    textAlign: "center",
                    fontSize: '15px',
                    fontWeight: '700',
                    padding: '5px 10px',
                    color: "white",
                    border: "none",
                    backgroundColor: "#F4772F",
                    zIndex: 5,
                    '&:hover': {
                        backgroundColor: "#F4772F"
                    }
                }
            },
            '& svg': {
                fontSize: '20px',
                "@media (min-width: 700px)": {
                    color: 'white'
                }
            },
            '& span': {
                color: 'gray',
                fontSize: '14px',
                fontWeight: '500'
            }
        }
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: "40%",
        backgroundColor: theme.palette.background.paper,
        border: '1px solid lightgrey',
        boxShadow: theme.shadows[5],
        padding: '0px',
        "@media (max-width: 1280px)": {
            width: "50%"
        },
        "@media (max-width: 912px)": {
            width: "70%"
        },
        "@media (max-width: 550px)": {
            width: "90%"
        }
    },
    title: {
        margin: '25px',
        '& p': {
            textAlign: 'center',
            margin: '10px'
        }
    },
    modalButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        margin: '25px',
        '& button': {
            width: '100%',
            textAlign: "center",
            textDecoration: 'none',
            color: "white",
            border: "none",
            backgroundColor: "#f44336",
            '&:hover': {
                backgroundColor: "#ed574c"
            }
        }
    },
    saunaOnboard: {
        margin: '10px',
        display: 'flex',
        zIndex: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        "@media (max-width: 450px)": {
            margin: '10px',
            display: 'flex',
            zIndex: 5,
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        "@media (min-width: 700px)": {
            margin: '6px 10px 0px 10px',
            position: 'absolute',
            right: '10px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center'
        }
    },
    changeEmailWithLabel: {
        width: 'auto',
        height: 'auto',
        zIndex: 5,
        '& button': {
            backgroundColor: '#f44336',
            width: '100%',
            border: 'none',
            padding: '10px',
            color: 'white',
            textAlign: 'center',
            textDecoration: 'none',
            fontSize: '14px',
            '&:hover': {
                backgroundColor: "#ed574c"
            }
        }
    },
    labelingDiv: {
        display: 'flex',
        marginRight: '20px',
        "@media (max-width: 450px)": {
            marginBottom: '15px'
        }
    },
    labelColor: {
        height: 12,
        width: 12,
        border: '1px solid black',
        backgroundColor: "#fffcbb",
        justifyContent: "center",
        marginTop: 5,
        marginLeft: 5,
        marginRight: 5,
        borderRadius: 2
    },
    labelText: {
        marginTop: 0
    },
    dropDown: {
        marginTop: '5px',
        width: '100%'
    },
    input: {
        width: '100%',
        height: '35px',
        borderRadius: '5px',
        border: '1px solid darkgrey',
        textIndent: '10px',
        padding: '0px',
        margin: '10px 0px'
    }
}));